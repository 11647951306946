import { BrowserClient } from '@sentry/browser';
import { SDK } from '@didomi/utility';
import { HubErrorEvent, HubClientConfig, SpaError } from './error-hub-types';

const createSentryClient = ({ dsn, release, environment }: HubClientConfig) => {
  return new BrowserClient({
    dsn,
    release,
    environment: environment || 'production',
    // Set tracesSampleRate to 1 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1,
  });
};

export const createErrorHub = () => {
  const activeClients: Record<string, BrowserClient> = {};
  let isSentryEnabled = false;

  // Set listeners to check whether Sentry is enabled
  window['didomiOnReady'] = window['didomiOnReady'] || [];
  window['didomiOnReady'].push(function () {
    setPermissions();
  });
  window.addEventListener('consent.changed', (e) => setPermissions);

  // Update isSentryEnabled depending whether user agreed or disagreed to use it
  const setPermissions = () => {
    isSentryEnabled = SDK.isVendorEnabled('c:sentry');
  };

  // Create new Sentry Client and add it to the activeClients
  const addClient = (config: HubClientConfig) => {
    const client = createSentryClient(config);
    activeClients[config.dsn] = client;

    return client;
  };

  // Return existing or create new client
  const getClient = ({ dsn, release, environment }) => {
    return activeClients[dsn] || addClient({ dsn, release, environment });
  };

  // Get extra context to be sent to Sentry with the error
  const getContext = (error: SpaError) => {
    return {
      extra: {
        url: window.location.href,
        user_agent: window.navigator.userAgent,
        screen_size: `${window.innerWidth}x${window.innerHeight}`,
        request_metadata: error?.request_metadata,
      },
    };
  };

  // Log error to console and report it to Sentry if it was enabled
  const reportError = ({ dsn, release, environment, error }: HubErrorEvent) => {
    if (isSentryEnabled) {
      const client = getClient({ dsn, release, environment });
      client.captureException(error, { captureContext: getContext(error) });
    }
  };

  return { reportError };
};
