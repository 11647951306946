import { AccessManagement } from '@didomi/utility';
import { ACCESS_POLICIES_CONFIG } from '../constants/access-policies';

// Mapping of url path to access policies that grant access to the path
export const ACCESS_POLICIES_PER_PATH = {
  'analytics': ACCESS_POLICIES_CONFIG.ANALYTICS_VIEWER,
  'versions-and-proofs': [
    ...ACCESS_POLICIES_CONFIG.NOTICE_VERSIONS_VIEWER,
    ...ACCESS_POLICIES_CONFIG.CONSENT_PROOFS_VIEWER,
  ],
  'data-manager': ACCESS_POLICIES_CONFIG.DATA_MANAGER_VIEWER,
  'organization/users': ACCESS_POLICIES_CONFIG.USERS_AND_ROLES_VIEWER,
  'organization/roles': ACCESS_POLICIES_CONFIG.USERS_AND_ROLES_VIEWER,
  'organization/settings': ACCESS_POLICIES_CONFIG.ORG_SETTINGS_VIEWER,
  'organization/sso': ACCESS_POLICIES_CONFIG.ORG_SETTINGS_EDITOR,
  'organization/keys': ACCESS_POLICIES_CONFIG.ORG_SETTINGS_VIEWER,
  'consent-notices': ACCESS_POLICIES_CONFIG.CMP_VIEWER,
  'smart-vendor-list': [
    ...ACCESS_POLICIES_CONFIG.CMP_EDITOR,
    ...ACCESS_POLICIES_CONFIG.COMPLIANCE_REPORT_VIEWER,
  ],
  'standard-text': ACCESS_POLICIES_CONFIG.CMP_EDITOR,
  'agnostik-compliance-report': ACCESS_POLICIES_CONFIG.COMPLIANCE_REPORT_VIEWER,
  'privacy-center': ACCESS_POLICIES_CONFIG.PRIVACY_CENTER_VIEWER,
  'preference-management': [
    ...ACCESS_POLICIES_CONFIG.PMP_WIDGETS_VIEWER,
    ...ACCESS_POLICIES_CONFIG.CONFIGURATION_TREE_VIEWER,
  ],
};

// Extract main and secondary path
const parsePathname = () => {
  // e.g. ['', 'didomi', 'versions-and-proofs', 'versions']
  const pathSegments = window.location.pathname.split('/');

  // Get main path - e.g. versions-and-proofs
  const mainPath = pathSegments[2];

  // Get secondary path - e.g. versions-and-proofs/versions
  const secondaryPath = `${pathSegments[2]}/${pathSegments[3]}`;

  return { mainPath, secondaryPath };
};

// Check access for the current url pathname
export const hasAccessToPath = async () => {
  const { mainPath, secondaryPath } = parsePathname();

  const policiesForPath =
    ACCESS_POLICIES_PER_PATH[secondaryPath] ||
    ACCESS_POLICIES_PER_PATH[mainPath];

  // If path has some policies that restrict access - check it
  return policiesForPath
    ? await AccessManagement.hasAccessPolicies(policiesForPath)
    : true;
};
