/**
 * Accessing these routes will not cause authentication check.
 * These routes will be accessible to non-authenticated users only.
 * NOTE: The route must start with a '/' (slash)
 */
const publicRoutes = ['/auth'];

/**
 * These routes will be accessible to both authenticated and unauthenticated users for the console application.
 */
const consoleHybridRoutes = ['public'];

/**
 * These routes will be accessible to both authenticated and unauthenticated users for the self-service compliance report application.
 */
const ssReportHybridRoutes = ['share/compliance-report'];

/**
 * These routes will show the shell without the topnav and sidenav
 */
const fullPageRoutes: Array<string | RegExp> = [
  /\/preference-management\/widgets\/.*?\/preview/,
  /\/privacy-requests\/widgets\/.*?\/preview/,
];

/**
 * Checks if the second segment of the current URL contains the SPAs route.
 *
 * @param route
 */
export const isActiveRoute = (route: string, skipOrgPath = false): boolean => {
  const pathSegments = window.location.pathname.split('/');
  const startPathIndex = skipOrgPath ? 1 : 2;

  return (
    pathSegments[startPathIndex] === route ||
    `${pathSegments[startPathIndex]}/${pathSegments[startPathIndex + 1]}` ===
      route
  );
};

/**
 * Checks if the current route is public (e.g. start with `/auth`)
 */
export const isCurrentRoutePublic = () => {
  return publicRoutes.some((publicRoute) =>
    location.pathname.startsWith(publicRoute),
  );
};

export const isCurrentRouteSAMLTest = () => {
  return location.pathname.startsWith('/auth/test');
};

/**
 * Checks if the current console route is a hybrid route
 */
export const isCurrentRouteConsoleHybrid = () => {
  return consoleHybridRoutes.some(
    (route) => location.pathname.split('/')[1] === route,
  );
};

/**
 * Checks if the current self-service route is a hybrid route
 */
export const isCurrentRouteSSReportHybrid = () => {
  return ssReportHybridRoutes.some((route) =>
    location.pathname.startsWith('/' + route),
  );
};

/**
 * Checks if the current route is a hybrid route
 */
export const isCurrentRouteHybrid = () => {
  return isCurrentRouteConsoleHybrid() || isCurrentRouteSSReportHybrid();
};

/**
 * Checks if any route is active
 */
export const isSomeRouteActive = (routes: string[]): boolean => {
  return routes.some((r) => isActiveRoute(r));
};

/**
 * Returns `true` if the route has changed.
 * Returns `false` if only the query params are updated.
 */
export const hasRouteChanged = (newUrl: string, oldUrl: string): boolean => {
  return getUrlWithoutQueryParams(newUrl) !== getUrlWithoutQueryParams(oldUrl);
};

/**
 * Returns the url without the query params.
 */
export const getUrlWithoutQueryParams = (url: string): string => {
  return url.split('?')[0];
};

/**
 * Check if the current route is a full page by looping over the fullPageRoutes to find a match.
 * @returns true if the route match with fullPageRoutes config
 */
export const isCurrentRouteAFullPage = (): boolean => {
  const path = window.location.pathname;
  return !!fullPageRoutes.find((routeMatch) => {
    if (typeof routeMatch === 'string') return path === routeMatch;

    return routeMatch.test(path);
  });
};
