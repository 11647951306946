export enum HubErrorEventType {
  SPA_ERROR = 'on-spa-error',
}

export interface HubClientConfig {
  dsn: string;
  release: string;
  environment?: string;
}

export interface HubErrorEvent extends HubClientConfig {
  error: SpaError;
}

export interface SpaError extends Error {
  request_metadata?: unknown;
}
