import { createErrorHub } from './error-hub';
import { HubErrorEventType, HubErrorEvent } from './error-hub-types';

export const initErrorHub = () => {
  const errorHub = createErrorHub();

  const handleError = (e: CustomEvent<HubErrorEvent>) => {
    errorHub.reportError(e.detail);
  };

  window.addEventListener(HubErrorEventType.SPA_ERROR, handleError);
};
